

.bg-body {
    background-color: transparent !important;
    padding: 1rem;
    grid-gap: 0px;
    --tw-text-opacity: 1;
    color: rgba(197,197,210,var(--tw-text-opacity));
}
#tab4-tab{
    border: none !important;
    border-bottom: 2px solid !important;
    --tw-border-opacity: 1;
    border-color: rgba(86,88,105,var(--tw-border-opacity));

}

#main_div a{
    font-size: 20px;
    background-color: transparent;
    --tw-text-opacity: 1;
    color: rgba(197,197,210,var(--tw-text-opacity));
}


.badge {
    display: inline-block;
    padding: 3px 10px;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 4px;
}

.text-dark-info {
    color: #028ee1 !important; }

    .text-dark-danger {
        color: #e6294b !important;
    }

    .bg-light-danger {
        background-color: #f9e7eb !important;
    }

    .bg-light-info {
        background-color: #cfecfe !important;
    }

    .card{
        background-color: transparent !important;
        --tw-text-opacity: 1;
        --tw-border-opacity: 0;
        color: rgba(197,197,210,var(--tw-text-opacity));
        border-color: rgba(86,88,105,var(--tw-border-opacity));
        border: none !important;
        padding: 0px !important;
        --bs-card-spacer-y: none !important;
         --bs-card-spacer-x: none !important; 

      }

      .colorCss{
        --tw-text-opacity: 1;
        color: rgba(197,197,210,var(--tw-text-opacity));

      }
      .bgColorNew, .sendbtn, body{
        --tw-bg-opacity: 1;

      }
      
      #SupplyChain, #Details, #homePage{
        --tw-bg-opacity: 1;
        background-color: #ffffff;

      
      }
      .borderColorNew{
        /* --tw-border-opacity: 1;
        border-color: rgba(86,88,105,var(--tw-border-opacity)) !important; */
    border: 1px solid black !important;
      }
      #chat_example .bg-light{
            background-color: transparent !important;
            border: 1px solid;
            border-color: rgba(86,88,105,var(--tw-border-opacity)) !important;
            border-radius: 10px;

      }


      #chat_pdf_input {
        color: black;
        outline: none !important;
        background-color: transparent !important;
        --tw-bg-opacity: 1;
        border: 1px solid black!important;

    }

    #textboxChats {
        position: sticky;
        top: calc(100vh - 110px); /* Adjust based on the height of #textbodyarea */
        --tw-bg-opacity: 1;
        background-color: rgba(64,65,79,var(--tw-bg-opacity));
        padding: 10px;
        border-top: 1px solid ;
        border-bottom: 1px solid ;
        --tw-border-opacity: 1;
        border-color: rgba(86,88,105,var(--tw-border-opacity)) !important;
        width: 100%; /* Adjust to fit the drawer width */
        z-index: 1;
      }
      
      #chat_pdf_input::placeholder {
        color: black !important;
    }
    #chat_pdf_input{
        outline: none !important;
    }

    #chat_pdf_input:focus {
        border: none !important; /* Change the border style/color on focus */
        outline: none; /* Remove default outline */
    }
    input#chat_pdf_input:focus {
        outline: none !important;
        box-shadow: none !important;
        border: 1px solid  !important;
        border-color: black !important;

    }

    #textbodyarea::-webkit-scrollbar{
        display: none !important;
    }

    .row>*{
        margin-top: 10px !important;
    }

    
    .faqActive{
        color: #bb1ccc !important;
        --accent-color: #bb1ccc;
        box-shadow: inset 4px 0 0 0 var(--accent-color);
        background-color: #fff;
      
        svg{
          color: #bb1ccc !important;
        }
        
      }