#plotly-popup {
  height: 100%;
  width: 100%;

  .css-1t1j96h-MuiPaper-root-MuiDialog-paper,
  .css-uhb5lp {
    max-width: unset !important;
    width: 95% !important;
    height: auto;

    .css-ypiqx9-MuiDialogContent-root {
      &::-webkit-scrollbar {
        width: 0px;
      }
    }
  }

  @media (max-width: 768px) {
    .css-1t1j96h-MuiPaper-root-MuiDialog-paper,
    .css-uhb5lp {
      width: 100% !important;
      height: 100% !important;
      margin: 0;
      // overflow-x: auto; // Enable horizontal scrolling

      .css-ypiqx9-MuiDialogContent-root {
        padding: 0;
        width: 100vw; // Ensure full viewport width

        &::-webkit-scrollbar {
          width: 0px;
        }
      }
    }
  }

  
}
