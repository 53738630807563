.login_body_grid {
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-columns: 65% 35%;
  overflow: hidden;
  background: url("../assets/videos//signin-bg.gif") no-repeat center;
  background-size: cover;
}

.left_grid_text {
  color: white;
  // width: 90%;
  // margin: auto;
  // display: block;
  text-align: left;
  width: 70%;
  line-height: 1.5;
  font-size: 18px;
  a{
    color: #fff;
    text-decoration: underline !important;
  }
}

.right_grid_align {
  height: 100%;
  display: grid;
  align-content: center;
  align-items: center;
  width: 100%;
  .right-container{
    width: 100%;
    .signin-text{
      color: #fff;
      margin-top: 20px;
      margin-bottom: 20px;
      font-size: 18px;
      letter-spacing: 1.1px;
    }
  }
}

.btn_login {
  height: 45px;
  color: #212121;
  border: none;
  width: 70%;
  border-radius: 6px;
  font-size: 18px;
  cursor: pointer;
  letter-spacing: 1.1px;
}

.left_margin {
  margin-top: 20px;
  padding-bottom: 20px !important;
}

.logout {
  cursor: pointer;
}

.login_body_content {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.loginPage {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.textAlign {
  text-align: left;
}

.MarsLogo {
  justify-content: center;
  align-content: center;
  width: 100%;
  display: grid;
}

.login_info {
  display: grid;
  justify-items: flex-start;
  align-items: center;
  align-content: center;
  height: 100%;
  width: 100%;
  .text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px 0px 0px 60px;
    .logo-img {
      width: 200px;
      margin-bottom: 24px;
    }
  }
}


.formLableText1{
  text-decoration: underline !important;
}