body {
  margin: 0;
  font-family: "Montserrat", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.org-tree-container {
  background-color: transparent !important;
}
.org-tree-node-label-inner {
  border-color: rgba(86, 88, 105, 1) !important;
  border-radius: 10px;
  color: #c5c5d2 !important;
}
.org-tree-node-btn {
  background-color: #343541 !important;
}
.horizontal .org-tree-node:not(:last-child):after {
  border-color: rgba(86, 88, 105, 1) !important;
}
.horizontal .org-tree-node-children:before {
  border-color: rgba(86, 88, 105, 1) !important;
}
.horizontal .org-tree-node:not(:first-child):before,
.horizontal .org-tree-node:not(:last-child):after {
  border-color: rgba(86, 88, 105, 1) !important;
}
.horizontal .org-tree-node:not(:first-child):before,
.horizontal .org-tree-node:not(:last-child):after {
  border-color: rgba(86, 88, 105, 1) !important;
}
.horizontal .org-tree-node:not(:first-child):before {
  border-color: rgba(86, 88, 105, 1) !important;
}
.horizontal .org-tree-node:not(:only-child):after,
.org-tree-node-btn {
  border-color: rgba(86, 88, 105, 1) !important;
}

.org-tree-node-label-inner {
  background-color: #b3d7ee !important;
  color: black !important;
}
/* Style the first level */
.org-tree-node-children > .org-tree-node .org-tree-node-label-inner {
  background-color: #cee7e9 !important;
}

/* Style the second level */
.org-tree-node-children
  > .org-tree-node
  .org-tree-node-children
  > .org-tree-node
  .org-tree-node-label-inner {
  background-color: #d9e3b0 !important;
}

/* Style the third level */
.org-tree-node-children
  > .org-tree-node
  .org-tree-node-children
  > .org-tree-node
  .org-tree-node-children
  > .org-tree-node
  .org-tree-node-label-inner {
  background-color: #fbd585 !important;
}

/* Style the fourth level */
.org-tree-node-children
  > .org-tree-node
  .org-tree-node-children
  > .org-tree-node
  .org-tree-node-children
  > .org-tree-node
  .org-tree-node-children
  > .org-tree-node
  .org-tree-node-label-inner {
  background-color: #febab2 !important;
}

.org-tree-node-btn:after {
  left: 47% !important;
  bottom: 3px !important;
}
