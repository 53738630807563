.response-cont {
  position: relative;
  height: 100%;
  text-align: left;
  padding: 1rem;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0px;
  }
  .loading-cont {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .loading-text {
      font-size: 18px;
      margin: 0;
      font-weight: 600;
      display: inline-block;
      white-space: nowrap;
    }
  }
}
