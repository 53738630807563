.query-container {
  position: relative;
  margin-bottom: 40px;
  .answer-card {
    .summary-section {
      margin: 16px 0px;
      h3 {
        font-size: 22px;
        font-weight: 600;
      }
      p {
        margin: 0;
        font-size: 16px;
        line-height: 1.5;
      }
    }
    .answer-cont-fav {
      margin: 24px 0px;
      background-color: #fff !important;
      border-radius: 4px;
      color: rgba(0, 0, 0, 0.87);
      position: relative;
      transition: box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      .avatar-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0px !important;
      }

      .main-card {
        display: flex;
        flex-wrap: wrap;
        overflow-wrap: break-word;
      }
      @media (max-width:769px) {
        .main-card {
          flex-direction: column;

        }
      }


    }
    .supporting-txt {
      font-size: 20px;
      font-weight: 600;
      text-decoration: underline;
      margin-bottom: 16px;
    }
    .pin-message {
      position: absolute;
      top: 6px;
      left: 40%;
    }
    .pin-cont {
      // margin-right: 0px !important;
      cursor: pointer;
      svg {
        fill: #7d7d7d;
      }
      .pinned {
        fill: #bb1ccc;
      }
    }
  }
}
.full-height {
  height: 100%;
}
