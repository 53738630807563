.faqSections {
  .history-text {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    white-space: pre-wrap;
  }
  .history-heading {
    text-align: left;
    margin: 0;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
  }
  .bg-dark-card {
    &:hover {
      color: #bb1ccc !important;
      --accent-color: #bb1ccc;
      box-shadow: inset 4px 0 0 0 var(--accent-color);
      background-color: #fff;
      svg {
        color: #bb1ccc !important;
      }
    }
  }
  .activeHistoryItem {
    color: #bb1ccc !important;
    --accent-color: #bb1ccc;
    box-shadow: inset 4px 0 0 0 var(--accent-color);
    background-color: #fff;
    svg {
      color: #bb1ccc !important;
    }
  }
}
