.sideNav {
  background-color: #2f2e35;
}
.nav_bar {
  background-color: #2f2e35;
}
.submit_btn {
  background-image: linear-gradient(
    243.04deg,
    #bb1ccc -1.66%,
    #bb1ccc 50.23%,
    #bb1ccc 99.97%
  ) !important;
}
.btn_login {
  background-image: linear-gradient(
    243.04deg,
    #ffffff -1.66%,
    #ffffff 50.23%,
    #ffffff 99.97%
  ) !important;
  color: #2f2e35;
  font-weight: 700;
}
.bgColor {
  --tw-bg-opacity: 1;
  background-color: #bb1ccc !important;
}
.headerTextFontcolorNew,
.loginText1 {
  color: #333 !important;
}
.form-select {
  border-color: #333 !important;
  height: 45px !important;
}
input[type="file" i] {
  height: 45px !important;
  border-color: #333 !important;
}

input[type="file"]::file-selector-button {
  background-color: #333;
  transition: 1s;
  height: 45px !important;
  color: #fff2f8 !important;
}
.items_menu {
  color: #333 !important;
  font-size: 18px !important;
}
.formLableText {
  color: #333 !important;
}
.formLableText1 {
  color: #fff2f8 !important;
}
.navBarStyle {
  background-color: #bb1ccc;
}
.navBarStyleColor {
  color: white !important;
}
.MuiListItemIcon-root {
  color: white !important;
}
.Mui-selected {
  color: #2f2e35 !important;
  text-decoration: none !important;
}
a {
  text-decoration: none !important;
}
.Mui-selected .MuiListItemIcon-root {
  color: #2f2e35 !important;
}
.MuiListItemButton-root:hover {
  color: white !important;
  // border-radius: 10px !important;
}
.Mui-selected :hover {
  background-color: transparent !important;
  // color: #3d3d3d !important;
}
#sidenavBarnew .Mui-selected {
  background-color: transparent !important;
  border-left:4px solid #bb1ccc;
  border-radius: 0px !important;
  color: #3d3d3d !important;
  .colorCss {
    color: #bb1ccc !important;
  }
  span{
    color: #bb1ccc !important;
  }
}

.navbgColor {
  --tw-bg-opacity: 1;
  background-color: rgba(32, 33, 35, var(--tw-bg-opacity));
}

.active {
  background-color: rgb(187, 28, 204) !important;
  --bs-pagination-active-bg: rgb(187, 28, 204) !important;
  border: none;
}
.active a {
  border: none;
}
.pagination .active {
  background-color: rgb(187, 28, 204) !important;
  --bs-pagination-active-bg: rgb(187, 28, 204) !important;
  /* border: 1px solid rgb(25, 115, 141) !important; */
}
.pagination {
  margin: 0 !important;
}
