.score-section-cont {
  margin: 24px 0px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 16px;
  h4 {
    font-size: 18px;
    font-weight: 600;
    padding-bottom: 1rem;
    padding-top: 1rem;
  }
}
@media (max-width:769px) {
  .score-section-cont {
    flex-direction: column;
    width: 75vh;

  }
}