.pinCls {
  gap: 10px;
  overflow: hidden;
  height: calc(100vh - 140px);
  overflow-y: auto;
  .pin-content {
    display: flex;
    border: 1px solid #eee;
    padding: 10px;
  }

  .item {
    display: grid;
    gap: 1rem;
    align-items: baseline;
    align-content: baseline;

    h3 {
      font-size: 16px;
      display: none;
    }
    p {
      font-size: 14px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .pin-list-items {
    // border: 1px solid #eee;
    padding: 10px;
    display: grid;
    gap: 2rem;
  }

  .item-list-content {
    border: 1px solid #eee;
    box-shadow: 0px 0px 4px;
    padding: 1rem;
    border-radius: 10px;
  }

  .content-list {
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    margin-top: 1rem;
    flex-wrap: wrap;
  }

  .content-list > div {
    flex: 1 1 49%; /* Flex-grow: 1, flex-shrink: 1, flex-basis: 49% */
    box-sizing: border-box; /* Ensures padding and border are included in the element's total width and height */
  }
  .overallsummary {
    font-size: 22px;
    font-weight: 600;
    padding-bottom: 1rem;
  }
}

html {
  overflow: hidden !important;
}

#mainFavorite {
  .loading-cont {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      font-size: 16px;
      font-weight: 500;
    }
  }
  .try-again-btn {
    font-weight: 600;
    color: #bb1ccc;
    cursor: pointer;
  }
}


