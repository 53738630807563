.response-card {
  background-color: #fff !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  //   box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25) !important;
  color: rgba(0, 0, 0, 0.87);
  position: relative;
  //   transition: box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  padding: 16px 32px 16px 16px;
  flex: 1 1 calc(50% - 10px); /* 50% width with some space adjustment */
  margin: 5px;
  box-sizing: border-box;
  position: relative;
  &:nth-child(odd):last-child {
    flex: 1 1 100%;
  }
  .first-info-cont {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
  }
  .read-more-btn {
    font-size: 16px;
    font-weight: 600;
    color: #bb1ccc;
    cursor: pointer;
  }
  .expand-icon {
    position: absolute;
    top: 10px;
    right: 0px;
    cursor: pointer;
    padding: 10px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background-color: rgb(238, 238, 238);
    }
    svg {
      font-size: 18px;
      color: #7d7d7d;
    }
  }
  .summary-text {
    // height: 70px;
    // overflow: hidden;
    p {
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      white-space: pre-wrap;
    }
    h3,
    p,
    li {
      font-size: 16px;
      font-weight: normal;
      line-height: 1.5;
      margin: 0;
    }
  }
  .response-header {
    font-size: 20px;
    font-weight: 600;
    width: 80%;
  }
}

@media (max-width:769px) {
  .response-card {
    border: 0px;
    border-radius: 0px;
    padding: 0;
    margin: 0px;

    .response-header {
      font-size: 15px;
      font-weight: 600;
      width: 100%;
    }

    .summary-text {
      p {
        font-size: 12px;
      }
    }
      .read-more-btn {
        font-size: 12px;
    }
  }
}