.DefaultPage {
  height: 100% !important;
  position: relative !important;
  display: flex !important;
  justify-content: flex-end;
  align-items: center;
  .side-image {
    position: absolute;
    bottom: -400px;
    left: -275px;
  }
  .default-container {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-right: 60px;
    .logo-section {
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      gap: 10px;
      margin-bottom: 12px;
      p {
        color: #fff;
        font-size: 2rem;
        font-weight: 600;
        line-height: normal;
        margin: 0 !important;
      }
      img {
        width: 100px;
        margin-bottom: 9px;
      }
    }
    .intro-section {
      color: #fff;
      font-size: 1.25rem;
      font-weight: 600;
      line-height: normal;
      text-align: left;
    //   width: 60%;
      //   padding-left: 12px;
      margin-bottom: 32px;
    }
    .description-text {
      color: #fff;
      font-size: 1rem;
      font-weight: 400;
      line-height: normal;
      text-align: left;
    //   width: 60%;
      margin-bottom: 24px;
    }
    .ask-me-btn {
      width: 120px;
      height: 41px;
      background: #bb1ccc;
      color: #fff;
      font-size: 1.24rem;
      font-weight: 600;
      line-height: normal;
      cursor: pointer;
      border: none;
      padding: 4px 6px;
      text-transform: capitalize !important;
      border-radius: unset !important;
    }
  }

  @media (max-width: 1400px) {
    justify-content: center !important;

    .default-container {
      width: 80%;
      align-items: center;
      margin-right: 0;

      .logo-section {
        justify-content: center;
        align-items: center;
        gap: 5px;

        p {
          font-size: 1.5rem;
        }

        img {
          width: 80px;
        }
      }

      .intro-section {
        font-size: 1rem;
        text-align: center;
      }

      .description-text {
        font-size: 0.875rem;
        text-align: center;
      }

      .ask-me-btn {
        width: 100px;
        height: 35px;
        font-size: 1rem;
      }
    }
  }
}
