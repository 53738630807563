#follow-response {
  margin-top: 24px;
  .query-section {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    .follow-query {
      display: flex;
      align-items: center;
      align-content: center;
      background: rgb(238, 238, 238);
      padding: 1rem 2.5rem 1rem 1rem;
      border-radius: 10px;
      margin-bottom: 0;
    }
  }
  .loading-cont-follow {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .fullHeightFollow {
    height: 60vh;
  }
}
