.App {
  text-align: center !important;
}
.App::-webkit-scrollbar {
  width: 0px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.inputTextboxHome {
  position: sticky;
  bottom: 0; /* Adjust based on the height of #textbodyarea */
  --tw-bg-opacity: 1;
  /* background-color: #2f2e35; */
  /* border: 1px solid #bb1ccc; */
  --tw-border-opacity: 1;
  width: 100%; /* Adjust to fit the drawer width */
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}
.inputTextboxHome .submit_btn {
  height: 37px !important;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1.2px;
  width: unset !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
  justify-content: flex-start !important;
}

.cardBoxCss {
  border-bottom: 1px solid rgba(193, 198, 222, 1) !important;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
  border-radius: 20px;
  border: 0px, 0px, 1px, 0px;
  padding: 15px;
  justify-content: space-between;
  background-color: white !important;
}
.cardBox1 {
  display: grid;
  grid-template-columns: 60% 40%;
  align-items: center !important;
  align-content: center !important;
  grid-gap: 10px;
  width: 100%;
}

.historyData {
  text-align: left;
  padding: 10px;
  display: grid;
  justify-content: flex-start;
  padding-bottom: 0px;
}
.historyItems {
  border: 1px solid #282c34;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  grid-gap: 10px;
  align-items: center;
  align-content: center;
}
.Recent_history {
  text-align: left;
  width: 100%;
  display: grid;
  justify-content: flex-start;
  padding: 10px;
  font-size: 20px;
  font-weight: 600;
}
.Recent_Clear {
  text-align: left;
  width: 100%;
  display: grid;
  justify-content: flex-end;
  padding: 10px;
  font-size: 18px;
  padding-right: 1rem;
}

.card-layout-secrion {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 1rem;
  align-content: flex-start;
}

.card-layout-secrion > div {
  flex: 49%; /* or - flex: 0 50% - or - flex-basis: 50% - */
  /*demo*/
}

.cardBoxCss {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 1rem;
  align-items: flex-start;
  align-content: flex-start;
}

@media print {
  @page {
    size: A4; /* Set the page size to A3 */
    margin: 20px;
    display: block;
    width: 90%;
  }
  /* .apexcharts-toolbar {
    display: none !important;
  } */
  .apexcharts-canvas {
    width: 100% !important;
  }
  .submit_btn,
  .export-button,
  .apexcharts-toolbar {
    display: none !important;
  }
}

.chatRoomTab {
  border: 1px solid #eee;
  text-align: center;
  height: 50px;
  display: grid;
  align-items: center;
  border-left: 0px;
  border-top: 0px;
  border-right: 0px;
  cursor: pointer;
}
#chatRoomHeader {
  position: sticky;
  top: 0;
  z-index: 1;
}
.tabSelected {
  color: #2f2e35;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  border-bottom: 3px solid #2f2e35;
}

#FaqSectionsBot {
  position: sticky;
  top: 0;
  z-index: 1;
}

/* LikeDislike.css */

.like-dislike-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100px;
  margin: 10px;
}

.like-button,
.dislike-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.count {
  font-size: 1.2rem;
  margin-top: 4px;
}

.btnSelectimg {
  background-color: #fff;
  border: none;
  border-radius: 100%;
  height: 40px;
  position: absolute;
  right: 19%;
  top: 45%;
  width: 40px;
}

#typingid {
  margin: 12px;
  margin-bottom: 0px;
  margin-left: 20px;
}
#typingid span {
  width: 12px;
  height: 12px;
  background-color: #ed49fe;
  display: inline-block;
  margin: 1px;
  border-radius: 50%;
  text-align: left;
}
#typingid span:nth-child(1) {
  -webkit-animation: bounce 1s infinite;
  animation: bounce 1s infinite;
}
#typingid span:nth-child(2) {
  -webkit-animation: bounce 1s infinite 0.2s;
  animation: bounce 1s infinite 0.2s;
}
#typingid span:nth-child(3) {
  -webkit-animation: bounce 1s infinite 0.4s;
  animation: bounce 1s infinite 0.4s;
}

@-webkit-keyframes bounce {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(8px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(8px);
  }
  100% {
    transform: translateY(0px);
  }
}

/* Add more styles for additional children as needed */

@keyframes fadeIn {
  100% {
    opacity: 1; /* When animation completes, set opacity to 1 */
  }
}

iframe {
  width: 100%;
  height: 100vh;
  border: none;
}
.report-style-class {
  height: 100% !important;
  width: 100% !important;
}

/* .DefaultPage .start-section {
  height: 100%;
  padding: 30px;
  grid-template-columns: repeat(1, 100%);
  padding-top: 10px;
  padding-left: 25px;
  justify-content: flex-start;
}
.DefaultPage .start-section .start-text {
  width: 40%;
  display: grid;
}

.DefaultPage .start-section .start-text .start-text-heading {
  font-size: 22px;
  font-weight: 500;
  color: white;
  margin-bottom: 24px;
  line-height: 24px;
  font-family: Arial, Helvetica, sans-serif;
}
.DefaultPage .start-section .start-text .get-start-btn {
  width: 140px;
  padding: 8px;
  border-radius: 10px;
  text-align: center;
  border: 1px solid #999 !important;
  color: white;
  padding-top: 6px;
  background-color: #bb1ccc;
} */
/* .btn-dialog-secondary:hover{
  background-image: none !important;
  background-color: #0000a0 !important;
  border: none !important;
  color: #fff !important;
  transition: 3s;
 } */

.DefaultPage {
  /* background: rgb(88,62,145);
background: -moz-linear-gradient(51deg, rgba(88,62,145,1) 0%, rgba(142,142,252,0.27503501400560226) 67%);
background: -webkit-linear-gradient(51deg, rgba(88,62,145,1) 0%, rgba(142,142,252,0.27503501400560226) 67%);
background: linear-gradient(51deg, rgba(88,62,145,1) 0%, rgba(142,142,252,0.27503501400560226) 67%);*/
  /* filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#583e91",endColorstr="#8e8efc",GradientType=1); */
  background-image: url("./app/assets/img/default-page-bg.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* filter: brightness(90%); */
  height: 100% !important;
}

.human-reviewer-disclosure-banner {
  /* Add your styles for the main container here */
  background-color: transparent;
  display: flex;
  gap: 1rem;
  align-items: baseline;
  align-content: baseline;
  margin: 10px;
  border-radius: 6px;
  padding: 15px;
  border: 1px solid #bb1ccc;
}
.tooltip-content {
  text-align: left;
}
.faq-items {
  border: 1px solid #bb1ccc;
  padding: 1rem;
  border-radius: 6px;
}

.navHistory {
  height: auto;
  overflow: hidden;
  padding: .5rem;
  /* overflow-y: auto; */
}
.navHistory::-webkit-scrollbar {
  width: 0px !important;
}
.NameHeader {
  color: white !important;
  text-align: left;
  font-size: 14px !important;
}
.NameHeadeEmail {
  font-size: 13px !important;
  color: white !important;
  text-align: left;
}

.response-item {
  background-color: #fff !important;
  border-bottom: 1px solid #c1c6de !important;
  border: 0 0 1px;
  border-radius: 20px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25) !important;
  padding: 20px;

  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  position: relative;
}

.TotalCls{
  background-color: #eee;
  color: black;
  font-weight: 600;
}

