#replot-dialog {
  .button-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 14px;
    .changes-btns {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 12px;
      .updating {
        width: 142px !important;
      }
    }
    .action-btns {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 12px;
      .submit_btn {
        width: unset !important;
        padding: 0px 10px !important;
      }
      .updating {
        width: 142px !important;
        padding: 0 !important;
      }
    }
  }
  .move-right {
    justify-content: flex-end;
  }

  .header-list {
    transition: all 0.3s;
    &:hover {
      background-color: #bb1ccc;
      color: #fff;
    }
  }
  .active-header {
    background-color: #bb1ccc;
    color: #fff;
  }
}
