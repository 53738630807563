.messaageScreen{
    // background-image: url("../../app/assets/img/main-background.png");
    height: 100%;
    align-items: baseline;
    align-content: baseline;
    color: black !important;
    display: grid;
    grid-gap: 1rem;
    justify-items: flex-start;
    justify-content: flex-start;
    padding: 2rem;
    color: white;
  
    @media (max-width: 768px) {
      justify-items: center;
      padding: 1rem;
      text-align: center;
  
      .human-reviewer-disclosure-banner {
        text-align: center;
        .tooltip-icon {
          justify-content: center;
        }
        .message-container {
          justify-content: center;
          .tooltip-content {
            text-align: center;
          }
        }
      }
    }
  }
  
