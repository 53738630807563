.workbench-sql-edit-icon {
    position: absolute;
    top: 35px;
    right: 25px;
    cursor: pointer;
  }
  
  .work-bench-cancel {
    width: 120px !important;
    height: 40px !important;
  }
  .work-bench-save,
  .work-bench-cancel {
    width: 120px !important;
    border-radius: 4px;
  }
  