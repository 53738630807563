#mainHistory {
  .loading-cont {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      font-size: 16px;
      font-weight: 500;
    }
  }
  .try-again-btn {
    font-weight: 600;
    color: #bb1ccc;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    .loading-cont {
      p {
        font-size: 14px;
      }
    }   
  }
}
