.query-container {
  position: relative;
  margin-bottom: 40px;

  .answer-card {
    .summary-section {
      margin: 16px 0px;

      h3 {
        font-size: 22px;
        font-weight: 600;
      }

      p {
        margin: 0;
        font-size: 16px;
        line-height: 1.5;
      }
    }

    @media (max-width:769px) {
      .summary-section {
        margin: 0px;

        h3 {
          font-size: 12px;
        }

        p {
          font-size: 12px;
        }
      }


    }

    .answer-cont-history {
      margin: 24px 0px;
      background-color: #fff !important;
      border-bottom: 1px solid #c1c6de !important;
      border: 1px solid rgba(0, 0, 0, 0.12);
      border-radius: 4px;
      color: rgba(0, 0, 0, 0.87);
      padding: 20px;
      position: relative;
      transition: box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0ms;

      .avatar-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;
      }


      @media (max-width:769px) {
        .avatar-container {
          .MuiAvatar-root {
            height: 25px !important;
            width: 25px !important;
            font-size: 15px !important;
          }
        }

      }


      .main-card {
        display: flex;
        flex-wrap: wrap;
        overflow-wrap: break-word;

      }

      @media (max-width:769px) {
        .main-card {
          flex-direction: column;

        }
      }



    }

    .supporting-txt {
      font-size: 20px;
      font-weight: 600;
      text-decoration: underline;
      margin-bottom: 16px;
    }

    @media (max-width:769px) {
      .supporting-txt {
        font-size: 15px;

      }
    }

    .pin-message {
      position: absolute;
      top: 6px;
      left: 40%;
    }

    .pin-cont {
      margin-right: 16px;
      cursor: pointer;

      svg {
        fill: #7d7d7d;
      }

      .pinned {
        fill: #bb1ccc;
      }
    }
    .main-card{
      @media (max-width:769px) {
        flex-direction: column;
      }
    }
  }
}

.full-height {
  height: 100%;
}