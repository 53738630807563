@media screen and (min-width:100px) and  (max-width:967px) {  
   
  .loginPage{
    background-color: black !important;

    .login_body_grid{
      grid-template-columns: none !important;
      background: black !important;  
      height: 80vh !important;
  }

  #Login_herf{
    a{
      font-size: 12px !important;
    }
  }
 

  .login_info{
    // height: auto;
    // display: block !important;
    // height: auto !important;
  }
  
  .btn_login{
    font-size: 14px !important;
    background-image: none !important;
    background-color: #bb1ccc;
  }
  .logo-img{
    width: 100px !important;
    padding-top: 20px;
  }
  .text-container{
    h1{
      font-size: 18px !important;
      width: 100% !important;
    }
    .left_grid_text{
      font-size: 12px !important;
      width: 100% !important;
    }
  }
  .login_info .text-container{
    padding: 2rem !important;
    padding-bottom: 0px !important;
    padding-top: 0px !important;
  }

  .right_grid_align{
    padding-top: 0rem;
    display: block !important;
    align-content: baseline;
    img{
      width: 50px !important;
    }
    .signin-text{
      font-size: 14px !important;
    }
  }

  }

  .default-home-page{
    .intro-section{
      font-size: 12px !important;
    }
    .description-text{
      display: none;
    }
  }
  
  #mainHome{
    .human-reviewer-disclosure-banner{
      display: none !important;
    }
    #statistic{
        height: 80% !important;
    }
    .messaageScreen{

      img{
        width: 50px !important;
      }
      justify-content: flex-start !important;
      justify-items: flex-start !important;
      padding: 0px !important;
      padding-top: 10px !important;
      div{
        text-align: left !important;
        font-size: 14px !important;
      }
    }
    .card-body{
      div{
        gap: 2px !important;
      }
     .faqtext{
          span{
            font-size: 12px !important;
          }
      }
    }

    .inputTextboxHome{
      gap: 10px !important;

     
      div{
        width: 100px !important;
      }
      .input-group{
        width: 100% !important;
      }

        .submit_btn{
          font-size: 10px !important;
        }
      
    }
  }

  .response-item{
    div,p{
      font-size: 12px !important;

    }
  }

  .response-cont{
    padding: 2px !important;

    .supporting-txt{
      padding-top: 10px !important;
    }

    .main-card{
      gap: 20px;
      .response-card{
        box-shadow: 0px 0px 4px rgba(0,0,0,0.5);
        padding: 10px;
        border-radius: 6px;
      }
    }
   
  }

  #chat_pdf_input{
    font-size: 12px !important;
  }

  .g-gtitle{
    font-size: 12px !important;
  }
  .response-card{
    // display: grid !important;
    // justify-content: flex-start !important;
    // justify-items: flex-start !important;
    // // div{
    //   width: 100% !important;
    //   font-size: 12px !important;
    // }

   

    .mobile-header-layout{
        display: flex;
        align-items: center !important;
        align-content: center !important;
    }
    .response-header{
      font-size: 12px !important;
      margin-bottom: 0px !important;
    }

    .mobile-score-layout{
      gap: 2px !important;
      div{
        font-size: 10px !important;
      }
    }

    .three-dots-icon {
      font-size: 10px !important;
      padding: 0px !important;
    }
  }

  .avatar-container{
    display: none !important;
  }
  .answer-cont,.answer-cont-history{
    border: none !important;
    padding: 1px !important;
    box-shadow: none !important;
    border-bottom: none !important;

  }
  .user-select-none .svg-container{
      height: 200px !important;
      width: 200px !important;
  }
  .query-container {
      margin-bottom: 20px !important;
  }
  .loading-cont{
    img{
      width: 60px !important;
    }
  }

  .mobile-layout-user-query{
    padding: 12px !important;
    margin-top: 10px !important;
  }

  .loading-text{
    font-size: 12px !important;
  }
  .plotly-cont .submit_btn{
    font-size: 10px !important;
    width: 60px !important;
    height: 30px !important;
  }
  .mobile-layout-graph{


    svg{
   width: 300px !important;
   .g-gtitle{
    tspan{
      font-size: 12px !important;

    }
    .xtitle{
      tspan{
        font-size: 12px !important;
  
      }
    }
    
   
    
   }
    }
    .modebar-btn{
      display: none !important;
    }
    
  }

  .HideMoreInPrints{
    div{
      gap: 10px !important;
    }
      .MuiSvgIcon-fontSizeMedium{
        font-size: 16px !important;
      }
    
   
  }

  #chatTable{
    td,tr,th{
      font-size: 10px !important;
    }
  }
  .moblie-layout-sql-width{
    width: 100% !important;
  }

  .mobile-layout-sql{
    font-size: 8px !important;
  }
  .pagination{
    .page-item{
      a{
        font-size: 10px !important;

      }

    }
  }
.table-info-mobile-main{
  display: grid !important;
  gap: 10px;
  margin-bottom: 10px;
}
  .table-info-mobile{
    font-size: 10px !important;
    padding-bottom: 0px !important;
  }

  .export-button{
    width: 20px !important;
    height: 30px !important;
    padding: 0px !important;
    svg{
      font-size: 10px !important;
    }
  }
  #feedback .feedback{
    flex-wrap: wrap !important;
    font-size: 12px !important;
  }

  #feedback .items {
    font-size: 12px !important;
    
  }
  #feedback {
    .MuiDialog-paper{
      min-width: 300px !important;
      max-width: 300px !important;
    }
    h2{
      font-size: 16px !important;
    }
    .label{
      font-size: 14px !important;
    }
    .commentsArea{
      font-size: 12px !important;
    }
    button{
      font-size: 12px !important;
      padding: 5px !important;
      height: 30px !important;
      width: 100px !important;
    }

   

   

  }
  #message-popup .MuiDialogContent-root{
width: 100% !important;
overflow: hidden !important;
overflow-y: auto !important;
}

}

@media screen and (min-width:278px) and  (max-width:767px) {  
 
  .HistoryMain{
    #mainHistory{
      padding: 0px !important;
      .query-container .answer-card .answer-cont-history {
        padding: 0px !important;
        border: none !important;
        box-shadow: none !important;
      }
    }
    .query-container{
   
      div{
        span{
          font-size: 12px !important;
   
        }
      }
   
    }
    .summary-section{
      p{
        font-size: 12px !important;
      }
    }
  }
  }
  has context menu
.loader {
    width: 16px;
    height: 16px;
    position: relative;
    left: -15px;
    border-radius: 50%;
    color: rgba(64,65,79,1);;
    background: currentColor;
    box-shadow: 32px 0 , -32px 0 ,  64px 0;
  }
  
  .loader::after {
    content: '';
    position: absolute;
    left: -32px;
    top: 0;
    width: 16px;
    height: 16px;
    border-radius: 10px;
    background:#fff;
    animation: move 3s linear infinite alternate;
  }
  
  @keyframes move {
    0% , 5%{
      left: -32px;
      width: 16px;
    }
    15% , 20%{
      left: -32px;
      width: 48px;
    }
    30% , 35%{
      left: 0px;
      width: 16px;
    }
    45% , 50%{
      left: 0px;
      width: 48px;
    }
    60% , 65%{
      left: 32px;
      width: 16px;
    }
  
    75% , 80% {
      left: 32px;
      width: 48px;
    }
    95%, 100% {
      left: 64px;
      width: 16px;
    }
}

.loadingSpinnercss{
    color:white; 
    display:grid;
    justify-content:center;
    justify-items: center;
    align-items:center;
    align-content:center;
    justify-self: center;
    position:fixed;
    left:0;
    right:0;
    top:0;
    bottom:0;
    background:rgba(0,0,0,0.5);
    z-index:99999;
    text-align: center;
    grid-gap: 10px;
}