#feedback {
  .MuiDialog-paper {
    min-width: 600px;
    max-width: 600px !important;
  }

  .feedback {
    display: flex;
    gap: 10px;
  }
  .items {
    border: 1px solid #bb1ccc;
    padding: 10px;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
  }
  .commentsArea {
    border: 1px solid #bb1ccc;
    width: 100%;
    resize: none;
    border-radius: 6px;
    padding: 10px;
  }
  .comments-main {
    .label {
      font-weight: 500;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  .activeBtn {
    background-color: #bb1ccc;
    color: white;
  }
  .cancel-btn {
    border: 1px solid #bb1ccc;
    color: #bb1ccc;
    background: transparent;
    transition: all 0.3s;
    &:hover {
      background: #bb1ccc;
      color: white;
    }
  }
}
