.plotly-item-container {
  position: absolute;
  top: 35px;
  right: 20px;
  cursor: pointer;
  z-index: 1000;
  padding: 8px;
  svg {
    font-size: 24px;
    fill: #7d7d7d;
  }
  &:hover {
    background-color: #eee;
    border-radius: 50%;
  }
}
