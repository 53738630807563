.mainBody {
  margin-top: 75px;
  position: relative;
  margin-left: 250px;
  border: 1px solid;
  margin-right: 10px;
}
.userinfo {
  font-size: 24px;
  font-weight: 600;
}
.WelcomeBack {
  font-size: 18px;
  font-weight: 400;
}
.settings {
  background-color: white;
  right: 0;
  height: 88vh;
  box-shadow: 0px 0px 10px;
  width: 500px;
  overflow: hidden;
  position: fixed;
  z-index: 1;
  overflow-y: auto;
  display: none;
  padding: 10px;
}
.headerTextFontcolorNew {
  font-size: 24px !important;
  text-align: left !important;
  font-weight: bold !important;
  padding-bottom: 2rem;
  display: block;
}
.submit_btn {
  width: 142px !important;
  height: 40px !important;
  border: none !important;
  transition: all 0.3s !important;
  color: #fff !important;
  font-family: inherit !important;
  &:hover {
    opacity: 0.9 !important;
  }
}

.explain_btn{
 padding: 6px;
  border: 1px solid #bb1ccc !important;
  transition: all 0.3s !important;
  color: #bb1ccc !important;
  font-family: inherit !important;
  background-color: #fff;
  cursor: pointer;

  svg{
    font-size: 30px;
  }
  &:hover {
    background-color: #bb1ccc;
    color: #fff !important;
  }
}
.plotly-cont {
  .submit_btn {
    width: 97px !important;
    font-weight: 600;
    letter-spacing: 1.2px;
    text-transform: capitalize;
  }
}
.cancel-btn {
  border: 1px solid #bb1ccc !important;
  color: #bb1ccc !important;
  background: transparent !important;
  transition: all 0.3s !important;
  &:hover {
    background: #bb1ccc !important;
    color: white !important;
  }
}
.iconsDiv {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background: #f1f3f8;
  display: grid;
  align-items: center;
  justify-items: center;
}
.card-box {
  display: grid;
  grid-gap: 40px;
  align-items: center;
  align-content: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 1.8rem;
  border-radius: 10px;
  text-align: left;
  min-height: 135px;
  max-height: 135px;
  grid-template-columns: 10% 75%;
  cursor: pointer;
  position: relative;
  min-width: 320px;
  max-width: 320px;
}
.card-box::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  background: #0000a0;
  width: 8px;
  height: 100%;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.ContentDiv {
  display: grid;
  grid-gap: 5px;
}
.headerText {
  font-size: 22px;
  padding-bottom: 1rem;
  padding-top: 1rem;
  display: block;
}
.ContentDivText {
  font-weight: bold;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: initial;
  display: -webkit-inline-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-word;
  text-align: left;
  color: #0000a0;
}
.last_update {
  font-size: 12px;
  color: #808080;
  line-height: 1.2em;
}
.headerTextReport {
  margin-top: 1rem;
  font-weight: 600;
  color: #0000a0;
}
.dept-items {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  grid-gap: 1rem;
}
.box_link_items {
  text-decoration: none !important;
  color: initial !important;
}
.box_link_items:hover {
  text-decoration: none !important;
  background-color: transparent !important;
}
.header_icons_btn {
  display: flex;
  justify-content: space-between;
  margin-right: 10px;
}
.toogleIconsClass {
  color: #eee;
}

.navbar {
  --bs-navbar-hover-color: transparent !important;
}
#navbarScrollingDropdown:hover {
  background-color: transparent !important;
}

.dropdown-menu {
  background-color: white !important;
}
.logoutCss {
  bottom: 100px;
  cursor: pointer;
  position: absolute;
}

.tooltip_home {
  position: relative;
  display: inline-block;
}

.tooltip_home .tooltiptext_home {
  display: none;
  background-color: black !important;
  color: #fff !important;
  text-align: center;
  padding: 10px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  font-size: 16px;
  width: auto;
  min-width: 10px;
  max-width: 300px !important;
}

.tooltip_home:hover .tooltiptext_home {
  display: block;
}

.tooltip_home .tooltiptext_home::after {
  content: " ";
  position: absolute;
  bottom: 100%; /* At the top of the tooltip */
  left: 12%;
  margin-left: -1px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

#main {
  /* border: 1px dotted black; */
  height: calc(100vh - 100px);
  width: 100%;
  padding: 10px;
  overflow: hidden;
}

#mainHome,
#mainFavorite,
#mainHistory {
  /* border: 1px dotted black; */
  height: calc(100vh - 80px);
  width: 100%;
  padding: 10px;
  overflow: hidden;
}

#mainFavorite,
#mainHistory {
  position: relative;
}

.navButtonToggle {
  position: fixed !important;
  z-index: 1101;
  border: 1px dotted rgba(86, 88, 105, 1) !important;
  backdrop-filter: blur(6px);
  left: 286px !important;
  padding: 0 !important;
  top: 92px !important;
  background-color: white !important;
}
.loadingCss {
  display: grid;
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center;
  height: 100%;
}

.faqHeader {
  color: black;
  text-align: left;
  font-size: 20px;
  font-weight: 600;
}
.faqSection {
  text-align: left;
  color: black;
  padding-bottom: 1rem;
  padding-top: 0rem;
}
.bg-dark-card {
  --tw-text-opacity: 1;
  --tw-border-opacity: 1;
  color: white;
  // border-color: #eee;
  // border: 1px solid #eee;
  padding: 10px;
  background-color: transparent;
  border-radius: 6px;
}

#mainChatContent {
  position: relative;
  overflow: hidden;
}
#mainChatContent {
  display: grid !important;
  grid-template-columns: 21% 78%;
  grid-gap: 1rem;
  height: 100%;
}

#mainChatContent_new {
  position: relative;
  overflow: hidden;
  width: 95%;
  // max-width: 1000px;
  // min-width: 800px;
  margin: auto;
  display: block;
}
#mainChatContent_new {
  height: 100%;
}
.default-page {
  max-width: unset !important;
  min-width: unset !important;
  margin: 0 !important;
}
.faqtext {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  cursor: pointer;
}
.headerTextItems {
  text-align: left;
  padding: 10px;
  font-weight: 600;
  font-size: 20px;
}
.headerTextItems1 {
  text-align: left !important;
  padding: 10px;
  padding-top: 0px;
}

/* CalendarChart.css */
.calendar-chart {
  display: grid;
  grid-template-columns: repeat(7, 1fr); /* 7 columns for days of the week */
  gap: 4px; /* Adjust the gap between cells */
}

.calendar-cell {
  width: 40px; /* Adjust cell width */
  height: 40px; /* Adjust cell height */
  background-color: #e0e0e0; /* Adjust cell background color */
  border: 1px solid #ccc; /* Add borders if needed */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

/* Add custom styles for different data values */
.high-value {
  background-color: #ff5733;
}

.medium-value {
  background-color: #ffc733;
}

.low-value {
  background-color: #33ff57;
}

.fullHeight {
  height: calc(100vh - 80px) !important;
}

#statistic {
  width: 100%;
  position: relative;
  overflow: hidden;
  height: calc(100vh - 157px);
  overflow-y: auto;
}
#statistic::-webkit-scrollbar {
  width: 0px;
}
#statistic1 {
  overflow: hidden;
  // padding-bottom: 5rem;
  // height: calc(100vh - 10px);
  height: 100%;
  position: relative;
}
.faqSections {
  background-color: transparent;
  overflow: hidden;
  height: calc(100vh - 310px);
  overflow-y: auto;
  border-radius: 6px;
  padding-bottom: 5rem;
}
.faqSections::-webkit-scrollbar {
  width: 0px;
}

/* Track */
.faqSections::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.faqSections::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.faqSections::-webkit-scrollbar-thumb:hover {
  background: #555;
}
#statistic {
  background-color: white;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

.faqSections .mt-3 {
  margin-top: 0px !important;
}

.chatRoomTab {
  border: 1px solid #eee;
  text-align: center;
  height: 50px;
  display: grid;
  align-items: center;
  border-left: 0px;
  border-top: 0px;
  border-right: 0px;
  cursor: pointer;
}
#chatRoomHeader {
  position: sticky;
  top: 0;
  z-index: 1;
}
.tabSelected {
  color: #2f2e35;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  border-bottom: 3px solid #2f2e35;
}
.tabChatroom {
  font-weight: 500;
  color: #2f2e35;
  display: grid;
  grid-template-columns: 50% 50%;
  align-content: center;
  align-items: center;
  background: white;
  width: 100%;
}
.watermark {
  font-size: 30px;
  font-weight: 600;
  padding-left: 10px;
}

.logoutLayout {
  position: absolute !important;
  bottom: 10px !important;
}
.listToggle {
  // max-height: 400px;
  // min-height: 220px;
  // overflow: hidden;
}
.userinfo {
  font-size: 16px;
}
.fontsizeHeader {
  font-size: 14px !important;
  color: white !important;
  padding-top: 1px !important;
}

.active {
  background-color: rgb(187, 28, 204) !important;
  --bs-pagination-active-bg: rgb(187, 28, 204) !important;
  border: none;
}
.active a {
  border: none;
}
.pagination .active {
  background-color: rgb(187, 28, 204) !important;
  --bs-pagination-active-bg: rgb(187, 28, 204) !important;
  /* border: 1px solid rgb(187, 28, 204) !important; */
}

.pagination {
  margin: 0 !important;
}
.sidenavBarstyle {
  .MuiDrawer-paperAnchorLeft {
    overflow: hidden;
  }
}


// .summary-text {
//   /* Hide all headings and set font size for consistency */
//   h1, h2, h3, h4, h5, h6 {
//     display: none;
//     font-size: 16px;
//   }

//   /* Hide all paragraphs and lists by default */
//   p, ul {
//     display: none;
//   }

//   /* Display the first paragraph */
//   .summary-text > p:first-of-type {
//     display: block;
//   }

//   /* Hide all other paragraphs */
//   .summary-text > p {
//     display: none;
//   }
// }

  pre{
    code{
      white-space: break-spaces !important;
      font-family:  "Montserrat", sans-serif !important;
      
    }
  }


.summary-text{
  pre{
    code{
      white-space: break-spaces !important;
      font-family:  "Montserrat", sans-serif !important;
      overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    }
  }
}

:root {
  --app-height: 100vh;
 }


