.settings-container {
  position: absolute;
  top: 80px;
  right: 30px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px 12px;
  max-width: 230px;
  min-width: 230px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  animation: move-up 0.3s;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  .settings-list {
    list-style: none;
    margin-bottom: 0;
    padding: 0;
    .list-item {
      cursor: pointer;
      padding: 10px 12px;
      border-radius: 4px;
      &:hover {
        background-color: rgb(238, 238, 238);
      }
      .item-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 16px;
        .setting-icon {
          font-size: 24px;
          fill: #7d7d7d;
        }
        .setting-txt {
          margin: 0;
          font-size: 18px;
          font-weight: 500;
          color: #212121;
        }
      }
    }
  }
}

.animate-setting {
}

@keyframes move-up {
  from {
    opacity: 0;
    top: 120px;
  }
  to {
    opacity: 1;
    top: 80px;
  }
}
