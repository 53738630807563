.message {
  color: black;
  display: grid;
  gap: 20px;
}
#message_user_0 {
  margin-top: 20px;
}
#chatTable {
  border-collapse: collapse;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25) !important;
  // border-spacing: 5px;
  width: "100%";
  tr,
  td,
  th {
    // border: 1px solid #eee;
    padding: 10px;
  }
  td,
  th {
    width: 10%;
  }
  thead {
    tr {
      background: rgb(238, 238, 238);
      border-radius: 4px 4px 0px 0px;
      th {
        &:first-child {
          border-radius: 4px 0px 0px 0px;
        }
        &:last-child {
          border-radius: 0px 4px 0px 0px;
        }
      }
    }
  }
  tbody {
    tr {
      border-bottom: 2px solid rgb(238, 238, 238);
    }
  }
}
.js-plotly-plot {
  display: block !important;
}
.plot-container {
  width: 100% !important;
  height: 100% !important;
}
.svg-container {
  position: inherit !important;
}

@media print {
  .HideMoreInPrints {
    display: none !important;
  }
}

.HideMoreInPrints {
  margin-top: 12px;
  .submit_btn {
    padding-left: 12px !important;
    padding-right: 12px !important;
    width: unset !important;
    transition: all 0.3s;
    cursor: pointer;
    p {
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 1.2px;
    }
  }
}
.trimCls {
  font-size: 12px !important;
  color: white;
}

.chat-form {
  width: 100%;
}
.workbanchLayout{
padding: 8px !important;
}

.overall-summary-cont {
  background-color: #fff !important;
  border-bottom: 1px solid #c1c6de !important;
  border: 0 0 1px;
  border-radius: 20px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25) !important;
  padding: 20px;
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  margin-top: 24px;
}

.three-dots-icon {
  padding: 10px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: rgb(238, 238, 238);
  }
}
.option-active {
  background-color: rgb(238, 238, 238);
}
