.work-header-cont {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;

  p {
    margin: 0;
  }
}
.work-bench-cancel {
  width: 120px !important;
  height: 40px !important;
}
.work-bench-save,
.work-bench-cancel {
  width: 120px !important;
  border-radius: 4px;
}
