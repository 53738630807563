.work-options-container {
  position: absolute;
  top: 70px;
  right: 15px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px 12px;
  max-width: 260px;
  min-width: 260px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  animation: move-up 0.2s;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  .options-list {
    list-style: none;
    margin-bottom: 0;
    padding: 0;
    .list-item {
      cursor: pointer;
      padding: 10px 12px;
      border-radius: 4px;
      &:hover {
        background-color: rgb(238, 238, 238);
      }
      .item-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 16px;
        svg {
          font-size: 24px;
          fill: #7d7d7d;
        }
        .active-pin {
          fill: #bb1ccc;
        }
        .setting-txt {
          margin: 0;
          font-size: 18px;
          font-weight: 500;
          color: #212121;
        }
      }
    }
  }
}

.animate-setting {
}

@keyframes move-up {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
